import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import React, { useState } from "react";
import "./App.css";
import { AiFillDownCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FiCopy } from "react-icons/fi";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const copyAddress = () => {
    const copyText = document.getElementById("myInput");
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      <div className="app">
        <div className="cus-nav text-center py-4">
          <img
            className="img-fluid navlogo"
            src={require("./assets/logo1.png").default}
            alt=""
          />
        </div>

        <div className="section1 d-flex align-items-end justify-content-center">
          <div className="text-center">
            <div className="zi">
              <img
                onClick={handleShow}
                style={{ cursor: "pointer" }}
                className="img-fluid sec1_img"
                src={require("./assets/wlu2.jpeg").default}
                alt=""
              />
            </div>

            <Modal size="lg" centered show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="text-dark">
                  <div className="d-flex justify-content-between">
                    <div className="sec2_t2 font-weight-bold text-center">
                      Paul Reubens
                    </div>
                    <div
                      className="sec2_t2 font-weight-bold text-center"
                      onClick={handleClose}
                    >
                      x
                    </div>
                  </div>
                  <p className="sec2_t2 mt-3">
                    Who didn’t love Pee Wee Herman? RIP and three cheers for
                    Paul Reubens! The iconic actor and beloved super star of Pee
                    Wee’s Playhouse brought joy and laughter to millions around
                    the world. Pee Wee’s Big Adventure was Tim Burton’s first
                    (and arguably best) feature film. From the secret word to “I
                    know you are but what am I” the iconic catch phrases and
                    exuberance defined a generation. Say Pee Wee and everyone
                    over 35 will smile - the Tequila dance, La La Lala La, the
                    moments and memories will be cherished forever. $WELOVEYOU
                    Pee Wee Herman! Paul Reubens was the GOAT, hands down,
                    funniest dude ever.
                  </p>
                </div>
              </Modal.Body>
            </Modal>
            <div className="d-flex justify-content-center">
              <div className="blackbox d-flex justify-content-center align-items-center px-3">
                <div className="mx-1 d-flex align-items-center">
                  <a
                    className="mx-3 uuuuu"
                    href="https://www.tiktok.com/@tooncesoreilly"
                    target="_blank"
                  >
                    <img
                      className="img-fluid shlogo"
                      src={require("./assets/tiktok.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="abf mx-1">
                  <p className="m-0 p-0 text-left font-weight-bold sect_t1">
                    VOTED MOST LOVED!
                  </p>
                  {/* <Container>
                    <Row>
                      <Col>
                        <div
                          className="text-left"
                          onClick={() => setIsActive3(!isActive3)}
                        >
                          <AiFillDownCircle
                            onClick={handleShow}
                            className="jarrow"
                          />
                        </div>
                      </Col>
                      <Col className="border">
                        <div
                          style={{ color: "#f740d0" }}
                          className="font-weight-bold text-left sect_t2"
                        >
                          #weloveyou
                        </div>
                      </Col>
                    </Row>
                  </Container> */}
                  <div className="d-flex align-items-center">
                    <div
                      className="text-center mx-1"
                      onClick={() => setIsActive3(!isActive3)}
                    >
                      <AiFillDownCircle
                        onClick={handleShow}
                        className="jarrow"
                      />
                    </div>
                    <div
                      style={{ color: "#f740d0" }}
                      // mx-5
                      className="font-weight-bold mx-5 sect_t2"
                    >
                      #weloveyou
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="text-center mx-1"
                      onClick={() => setIsActive3(!isActive3)}
                    >
                      <AiFillDownCircle
                        onClick={handleShow}
                        className="jarrow"
                      />
                    </div>
                    <div
                      style={{ color: "#f740d0" }}
                      // mx-5
                      className="font-weight-bold mx-1 sect_t2"
                    >
                      #weloveyou
                    </div>
                  </div> */}
                  {/* <div className="mt-1 text-center">
                    <a
                      className="mx-2"
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x9b6884346a92a97676ee7e712b66d7b227af0c63"
                      target="_blank"
                    >
                      <img
                        className="img-fluid slo"
                        src={require("./assets/dext.svg").default}
                        alt=""
                      />
                    </a>
                    <a
                      className="mx-2"
                      href="https://dexscreener.com/ethereum/0x9b6884346a92a97676ee7e712b66d7b227af0c63"
                      target="_blank"
                    >
                      <img
                        className="img-fluid slo"
                        src={require("./assets/dexs.png").default}
                        alt=""
                      />
                    </a>

                    <a
                      className="mx-2"
                      href="https://etherscan.io/token/0x91261CebB5cF1BAccD3b240D9994A5670538f24C"
                      target="_blank"
                    >
                      <img
                        className="img-fluid slo"
                        src={require("./assets/eth.png").default}
                        alt=""
                      />
                    </a>
                  </div>
            
             */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sec2">
        <div className="my3">
          <div className="text-center">
            <div className="text-center">
              <a
                className=""
                href="https://www.dextools.io/app/en/ether/pair-explorer/0x9b6884346a92a97676ee7e712b66d7b227af0c63"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("./assets/dext.svg").default}
                  alt=""
                />
              </a>
              <a
                className=""
                href="https://dexscreener.com/ethereum/0x9b6884346a92a97676ee7e712b66d7b227af0c63"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("./assets/dexs.png").default}
                  alt=""
                />
              </a>

              <a
                className=""
                href="https://etherscan.io/token/0x91261CebB5cF1BAccD3b240D9994A5670538f24C"
                target="_blank"
              >
                <img
                  className="img-fluid slo"
                  src={require("./assets/eth.png").default}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="mt-4 sec2_box py-5">
            <h1 className=" text-center font-weight-bold sec2_t1">
              Who do{" "}
              <span
                className="font-weight-bold"
                style={{ borderBottom: "4px solid #000" }}
              >
                YOU
              </span>{" "}
              love?
            </h1>

            <p className="sec2_t2 text-center desk">
              <span className="font-weight-bold">$WELOVEYOU</span> is a{" "}
              <span className="font-weight-bold">meme coin</span> for lovers.
              Every month <br /> we'll vote to discover who’s loved most!
            </p>
            <p className="sec2_t2 text-center mob">
              <span className="font-weight-bold">$WELOVEYOU</span> is a{" "}
              <span className="font-weight-bold">meme coin</span> for the
              lovers. Every month we'll vote to discover who’s loved most!
            </p>

            <div className="text-center">
              <img
                className="img-fluid sec2_img px-5"
                src={require("./assets/wlu1.jpeg").default}
                alt=""
              />
            </div>
            {/* <div className="mt-5 text-center">
              <a
                target="_blank"
                href="https://app.uniswap.org/#/swap?exactField=&outputCurrency=0x91261CebB5cF1BAccD3b240D9994A5670538f24C"
                className="cusbtn"
              >
                Buy Now
              </a>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="text-center">
        <iframe
          src="https://app.uniswap.org/#/swap?exactField=&outputCurrency=0x91261CebB5cF1BAccD3b240D9994A5670538f24C"
          height="500px"
          width="100%"
          className="uni"
        />
      </div> */}

      <div className="sec3">
        <div className="d-flex justify-content-center">
          <div className="accordion">
            <div className="accordion-item">
              <div
                className="accordion-title d-flex justify-content-center"
                onClick={() => setIsActive3(!isActive3)}
              >
                <div className="">
                  <h1 className="sec3_t1 text-center ">ABOUT</h1>
                </div>
              </div>
              {isActive3 && (
                <div className="accordion-content mt-3">
                  <div className="">
                    <div className="">
                      <Container className="boxbuy">
                        <div className="bigtext">
                          <p className="font-weight-bold bigtextt">
                            Welcome to the world of Cryptocurrency!
                          </p>
                          <p className="aff bigtext">
                            Have you ever wondered what all the fuss is about?
                            Well{" "}
                            <span className="font-weight-bold">$WELOVEYOU</span>{" "}
                            is an easy, fun way to introduce yourself to crypto!
                            In a matter of minutes you can learn all about it
                            and participate in the global phenomenon.
                          </p>

                          <div
                            className="text-center"
                            onClick={() => setIsActive5(!isActive5)}
                          >
                            <AiFillDownCircle size={"30"} />
                          </div>

                          {isActive5 && (
                            <div className="mt-3">
                              <p className="aff bigtext">
                                <span className="font-weight-bold">
                                  Do not be alarmed.
                                </span>{" "}
                                Many people hear ‘Cryptocurrency’ and think of
                                danger and complexity. They think of fortunes
                                wagered and lost. While this may be true with
                                some coins out there ‘meme coins’ are just for
                                fun and education. How cool is that?
                              </p>
                              <p className="aff bigtext">
                                <span className="font-weight-bold">
                                  Here’s how it works….
                                </span>{" "}
                                You’ll learn about creating your own personal
                                wallet, picking a winner&nbsp; ($WELOVEYOU)
                                &nbsp; and then participating in the fun. Keep
                                up with us on Tik Tok to see when it’s time to
                                vote. Enter your choice in the comments and then
                                start sharing the love #weloveyou!
                              </p>
                              <p className="aff bigtext">
                                <span className="font-weight-bold">
                                  $WELOVEYOU is special{" "}
                                </span>
                                because it’s interactive. Fans around the world
                                will vote on Tik Tok to discover who’s the MOST
                                LOVED every month. The more people participate
                                the more valuable the coin gets. The more
                                valuable the coin gets the bigger it grows. The
                                bigger it grows the better votes get. The better
                                the votes get the more people participate… And
                                so on and so on…. Forever and ever!
                              </p>
                              <div className="text-center">
                                <img
                                  className="img-fluid let"
                                  src={require("./assets/let.jpeg").default}
                                  alt=""
                                />
                              </div>
                              <p className="font-weight-bold bigtextt">
                                Let’s go!
                              </p>
                              <p className="smmtaxt">
                                (Please remember this is for entertainment and
                                educational purposes only. Go to Vegas if you
                                want to lose a bunch of money. This one is for
                                LOVERS and HODLers - you’ll learn what that
                                means!))
                              </p>
                            </div>
                          )}
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <div
                className="accordion-title d-flex justify-content-center"
                onClick={() => setIsActive(!isActive)}
              >
                <div className="">
                  <h1 className="sec3_t1 text-center ">HOW TO BUY</h1>
                </div>
              </div>
              {isActive && (
                <div className="accordion-content mt-3">
                  <div className="">
                    <div className="">
                      <Container className="boxbuy">
                        <Row>
                          <Col lg="3">
                            <div className="text-center">
                              <img
                                className="buy_img"
                                src={require("./assets/b1.webp").default}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col lg="9">
                            <div className="">
                              <h4 className="flogo font-weight-bold">
                                Create a Wallet
                              </h4>
                              <p
                                style={{ fontSize: "1rem" }}
                                className="bigtext aff"
                              >
                                Download metamask or your wallet of choice from
                                the app store or google play store FOR FREE.
                                Desktop users, download the google chrome
                                extension by going to https://metamask.io/
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="boxbuy mt-5">
                        <Row>
                          <Col lg="3">
                            <div className="text-center">
                              <img
                                className="buy_img"
                                src={require("./assets/b2.png").default}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col lg="9">
                            <div className="">
                              <h4 className="flogo font-weight-bold">
                                Get Some ETH
                              </h4>
                              <p
                                style={{ fontSize: "1rem" }}
                                className="bigtext aff"
                              >
                                Have ETH in your wallet to switch to $WELOVEYOU.
                                If you don't have any ETH buy directly on
                                Metamask, transfer from another wallet, or buy
                                on another exchange and send to your wallet.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="boxbuy mt-5">
                        <Row>
                          <Col lg="3">
                            <div className="text-center">
                              <img
                                className="buy_img"
                                src={require("./assets/love.svg").default}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col lg="9">
                            <div className="">
                              <h4 className="flogo font-weight-bold">
                                Smart Contract Address
                              </h4>
                              <p
                                style={{ fontSize: "1rem" }}
                                className="bigtext aff"
                              >
                                This is like the secret code - enter this to
                                find $WELOVEYOU. Every NEW COIN has an address
                                like this!
                              </p>
                              <div
                                style={{ fontSize: "1rem" }}
                                className="bigtext aff "
                              >
                                copy this address - &nbsp;
                                <span className="AuthorProfileCard_box_info_address">
                                  <input
                                    type="text"
                                    value={
                                      "0x91261CebB5cF1BAccD3b240D9994A5670538f24C"
                                    }
                                    id="myInput"
                                  />
                                  <FiCopy onClick={() => copyAddress()} />
                                </span>
                                {/* 0x91261CebB5cF1BAccD3b240D9994A5670538f24C */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="boxbuy mt-5">
                        <Row>
                          <Col lg="3">
                            <div className="text-center">
                              <img
                                className="buy_img"
                                src={require("./assets/b3.png").default}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col lg="9">
                            <div className="">
                              <h4 className="flogo font-weight-bold">
                                Go to Uniswap
                              </h4>
                              <p
                                style={{ fontSize: "1rem" }}
                                className="bigtext aff"
                              >
                                Connect to Uniswap. Go to app.uniswap.org in
                                google chrome or in the browser inside your
                                Metamask app. Switch $ETH for $WELOVEYOU - done!
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <div
                className="accordion-title d-flex justify-content-center"
                onClick={() => setIsActive1(!isActive1)}
              >
                <div className="">
                  <h1 className="sec3_t1 text-center ">TOKENOMICS</h1>
                </div>
              </div>
              {isActive1 && (
                <div className="accordion-content mt-3">
                  <div className="">
                    <div className="">
                      <Container className="boxbuy">
                        <div className="">
                          <p
                            style={{ fontSize: "1rem" }}
                            className="text-center bigtext aff"
                          >
                            <span className="font-weight-bold">
                              420,000,000,000
                            </span>{" "}
                            token supply
                          </p>
                          <p
                            style={{ fontSize: "1rem" }}
                            className="text-center bigtext aff"
                          >
                            <span className="font-weight-bold">93.1%</span> sent
                            to the Liquidity Pool - LP tokens are burnt and
                            contract is renounced
                          </p>
                          <p
                            style={{ fontSize: "1rem" }}
                            className="aff bigtext"
                          >
                            <span className="font-weight-bold">6.9%</span> held
                            for future CEX, bridges, and liquidity pools
                          </p>
                          <p
                            style={{ fontSize: "1rem" }}
                            className="aff bigtext"
                          >
                            <span className="font-weight-bold">0%</span> Tax
                          </p>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <div
                className="accordion-title d-flex justify-content-center"
                onClick={() => setIsActive2(!isActive2)}
              >
                <div className="">
                  <h1 className="sec3_t1 text-center ">ROADMAP</h1>
                </div>
              </div>
              {isActive2 && (
                <div className="accordion-content mt-3">
                  <div className="">
                    <div className="">
                      <Container className="boxbuy">
                        <div className="">
                          <p
                            style={{ fontSize: "1rem" }}
                            className="aff bigtext"
                          >
                            <span className="font-weight-bold">PHASE - 1</span>
                            &nbsp; LAUNCH, 1000+ hodlers, TikTok voting begins
                          </p>
                          <p
                            style={{ fontSize: "1rem" }}
                            className="aff bigtext"
                          >
                            <span className="font-weight-bold">PHASE - 2</span>
                            &nbsp; 10,000+ hodlers, Newsletter, Discord group,
                            CEX
                          </p>
                          <p
                            style={{ fontSize: "1rem" }}
                            className="aff bigtext"
                          >
                            <span className="font-weight-bold">PHASE - 3</span>
                            &nbsp; 100,000+ hodlers, T1
                          </p>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <div
                className="accordion-title d-flex justify-content-center"
                onClick={() => setIsActive4(!isActive4)}
              >
                <div className="">
                  <h1 className="sec3_t1 text-center ">CONTACT US</h1>
                </div>
              </div>
              {isActive4 && (
                <div className="accordion-content mt-3">
                  <div className="">
                    <div className="">
                      <Container className="boxbuy">
                        <div className="">
                          <p className="bigtext aff">
                            TooncesOreilly@weloveyou.vip
                          </p>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="text-center">
            {/* <a
              className="pxx"
              href="https://twitter.com/TooncesOReilly"
              target="_blank"
            >
              <img
                className="img-fluid slogo"
                src={require("./assets/f3.png").default}
                alt=""
              />
            </a> */}
            <a
              className="mx-3"
              href="https://www.tiktok.com/@tooncesoreilly"
              target="_blank"
            >
              <img
                className="img-fluid slogo"
                src={require("./assets/tiktok.png").default}
                alt=""
              />
            </a>
            <span href="pxx">
              <img
                className="img-fluid slogo"
                src={require("./assets/logo.jpeg").default}
                alt=""
              />
            </span>
            <a
              className="pxx"
              href="https://t.me/WeLoveYouCoin"
              target="_blank"
            >
              <img
                className="img-fluid slogo"
                src={require("./assets/f4.png").default}
                alt=""
              />
            </a>
          </div>
        </div>

        <div className="mt-3 text-center">
          <p style={{ fontSize: "1.2rem" }} className="font-weight-bold stt">
            <span style={{ fontSize: "1rem" }}>@copyright</span> 2023 by
            WELOVEYOU * ALL RIGHTS RESERVED!
          </p>
        </div>
      </div>
    </>
  );
}

export default App;
